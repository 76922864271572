* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  height: 100vh;
  width: 100vw;
  font-family: "Rubik";
  background: linear-gradient(to left, #cac531, #f3f9a7);
  overflow-x: hidden;
}

.pokelogo {
  height: 8rem;
  width: 10rem;
  filter: brightness(0) saturate(100%) invert(34%) sepia(80%) saturate(5016%)
    hue-rotate(351deg) brightness(94%) contrast(105%);
  margin-left: 3rem;
}

main {
  height: 65vh;
  width: 100vw;
}

.hero {
  display: flex;
  color: white;
  margin-top: 4rem;
  justify-content: center;
  padding-left: 10rem;
  padding-right: 10rem;
}

.poke-num,
.poke-name {
  color: #f62d14;
  font-weight: 600;
}

.poke-type {
  display: inline-block;
  border-radius: 0.6rem;
  font-size: 1.5rem;
  padding: 0.5rem 0.75rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  border: 1px solid white;
}

.poke-num {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.poke-name {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.poke-text {
  font-size: 1.4rem;
  color: black;
  font-weight: 600;
  width: 20rem;
}

.info {
  width: 50%;
  height: 100%;
}

.poke-details {
  margin-top: 2rem;
  padding: 0.8rem 1.2rem;
  background-color: #f62d14;
  border: none;
  border-radius: 0.6rem;
  color: white;
  font-weight: 400;
  font-size: 1rem;
}

.pokemonimg {
  width: 50%;
  height: 100%;
}

.pokeimg {
  height: 23rem;
  margin-left: 3rem;
  margin-top: -6rem;
  
}

.flex {
  display: flex;
}

.input-group {
  justify-content: end;
  width: 72%;
  margin-top: 10rem;
}

input[type="text"] {
  width: 80%;
  height: 4rem;
  border-radius: 0.5rem;
  padding-left: 3rem;
  background-color: #ccc;
  outline: none;
  font-size: 2rem;
  color: black;
  font-weight: 600;
}

.cards {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #060b28;
  font-weight: 700;
  flex-wrap: wrap;
  overflow-x: hidden;
}

.card {
  border: none;
  padding: 4rem 4.5rem 4.5rem 4.5rem;
  border-radius: 2rem;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  color: white;
  margin-top: 20rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

a{
  text-decoration: none;
  color: white;
}

.pokemoncardimg {
  position: absolute;
  height: 15rem;
  transform: translate(-50%,-105%);
  cursor: pointer;
  transition: transform 1s ease;
}

.pokemon-no,
.pokemon-name {
  font-size: 2rem;
  font-weight: 500;
}

.pokemon-name{
  cursor: pointer;
  transition:transform 1s ease;
}
.pokemon-name:hover{
  transform: scale(1.2);
}

.type {
  gap: 1rem;
}

.pokemon-type {
  padding: 0.5rem 0.75rem;
  border: none;
  border-radius: 0.5rem;
}

.weight {
  margin-left: 5rem;
}

.height > p {
  display: grid;
  place-items: center;
  padding: 0.5rem 0.75rem;
}

.weight > p {
  display: grid;
  place-items: center;
  padding: 0.5rem 0.75rem;
}

button {
  width: 160%;
  height: 3rem;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  font-size: 1.5rem;
  font-weight: 600;
  border: none;
  color: black;
  cursor: pointer;
}

@media screen and (max-width: 920px) {
  body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .hero {
    display: flex;
    flex-direction: column;
    gap: 9rem;
    align-items: center;
    justify-content: center;
  }
  .info {
    width: 20rem;
  }
  .pokeimg {
    margin-left: -7rem;
    margin-top: -7rem;
  }
}
