* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: linear-gradient(to left, #cac531, #f3f9a7);
  width: 100vw;
  height: 100vh;
  font-family: Rubik;
  overflow-x: hidden;
}

.pokelogo {
  filter: brightness(0) saturate() invert(34%) sepia(80%) saturate(5016%) hue-rotate(351deg) brightness(94%) contrast(105%);
  width: 10rem;
  height: 8rem;
  margin-left: 3rem;
}

main {
  width: 100vw;
  height: 65vh;
}

.hero {
  color: #fff;
  justify-content: center;
  margin-top: 4rem;
  padding-left: 10rem;
  padding-right: 10rem;
  display: flex;
}

.poke-num, .poke-name {
  color: #f62d14;
  font-weight: 600;
}

.poke-type {
  border: 1px solid #fff;
  border-radius: .6rem;
  margin-bottom: .5rem;
  padding: .5rem .75rem;
  font-size: 1.5rem;
  font-weight: 600;
  display: inline-block;
}

.poke-num {
  margin-bottom: .5rem;
  font-size: 1.5rem;
}

.poke-name {
  margin-bottom: 1rem;
  font-size: 3rem;
}

.poke-text {
  color: #000;
  width: 20rem;
  font-size: 1.4rem;
  font-weight: 600;
}

.info {
  width: 50%;
  height: 100%;
}

.poke-details {
  color: #fff;
  background-color: #f62d14;
  border: none;
  border-radius: .6rem;
  margin-top: 2rem;
  padding: .8rem 1.2rem;
  font-size: 1rem;
  font-weight: 400;
}

.pokemonimg {
  width: 50%;
  height: 100%;
}

.pokeimg {
  height: 23rem;
  margin-top: -6rem;
  margin-left: 3rem;
}

.flex {
  display: flex;
}

.input-group {
  justify-content: end;
  width: 72%;
  margin-top: 10rem;
}

input[type="text"] {
  color: #000;
  background-color: #ccc;
  border-radius: .5rem;
  outline: none;
  width: 80%;
  height: 4rem;
  padding-left: 3rem;
  font-size: 2rem;
  font-weight: 600;
}

.cards {
  background: #060b28;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  font-weight: 700;
  display: flex;
  overflow-x: hidden;
}

.card {
  color: #fff;
  border: none;
  border-radius: 2rem;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
  margin-top: 20rem;
  margin-left: 2rem;
  margin-right: 2rem;
  padding: 4rem 4.5rem 4.5rem;
  display: flex;
  position: relative;
}

a {
  color: #fff;
  text-decoration: none;
}

.pokemoncardimg {
  cursor: pointer;
  height: 15rem;
  transition: transform 1s;
  position: absolute;
  transform: translate(-50%, -105%);
}

.pokemon-no, .pokemon-name {
  font-size: 2rem;
  font-weight: 500;
}

.pokemon-name {
  cursor: pointer;
  transition: transform 1s;
}

.pokemon-name:hover {
  transform: scale(1.2);
}

.type {
  gap: 1rem;
}

.pokemon-type {
  border: none;
  border-radius: .5rem;
  padding: .5rem .75rem;
}

.weight {
  margin-left: 5rem;
}

.height > p, .weight > p {
  place-items: center;
  padding: .5rem .75rem;
  display: grid;
}

button {
  color: #000;
  cursor: pointer;
  border: none;
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
  width: 160%;
  height: 3rem;
  font-size: 1.5rem;
  font-weight: 600;
}

@media screen and (width <= 920px) {
  body {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .hero {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 9rem;
    display: flex;
  }

  .info {
    width: 20rem;
  }

  .pokeimg {
    margin-top: -7rem;
    margin-left: -7rem;
  }
}
/*# sourceMappingURL=index.4c303454.css.map */
